import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName'

export const customerChecklistRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/public/customer-checklist/views/CustomerChecklistView.vue'),
		name: ROUTE_NAME.PUBLIC_CUSTOMER_CHECKLIST.INDEX,
		path: 'work-station-sheet',
	},
	{
		component: async () => import('@/modules/public/customer-checklist/views/CustomerChecklistDownloadView.vue'),
		name: ROUTE_NAME.PUBLIC_CUSTOMER_CHECKLIST.DOWNLOAD,
		path: 'work-station-sheet/download',
	},
]
