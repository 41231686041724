import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const compensationsRoutes: RouteRecordRaw[] = [
	{
		children: [
			{
				children: [
					{
						component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesStaffView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.ADVANCES.STAFF,
						path: 'staff',
					},
					{
						beforeEnter: permissionMiddleware([Permission.ADMIN]),
						component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesAdminView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.ADVANCES.ADMIN,
						path: 'admin',
					},
				],
				name: ROUTE_NAME.COMPENSATIONS.ADVANCES.INDEX,
				path: 'advances',
				redirect: { name: ROUTE_NAME.COMPENSATIONS.ADVANCES.STAFF },
			},
			{
				children: [
					{
						component: () => import('@/modules/compensations/views/payments/staff/CompensationsPaymentsStaffView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.STAFF,
						path: 'staff',
					},
					{
						beforeEnter: permissionMiddleware([Permission.ADMIN]),
						component: () => import('@/modules/compensations/views/payments/admin/CompensationsPaymentsAdminView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.ADMIN,
						path: 'admin',
					},
				],
				name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.INDEX,
				path: 'payments',
				redirect: { name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.STAFF },
			},
			{
				name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.INDEX,
				path: 'corrections',
				redirect: {
					name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS.INDEX,
				},
				component: () => import('@/modules/compensations/views/corrections/CompensationCorrectionView.vue'),
				children: [
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationCorrectionInProgressIndexView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS.INDEX,
						path: 'in-progress',
					},
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationCorrectionRequestedIndexView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REQUESTED.INDEX,
						path: 'requested',
					},
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationCorrectionApprovedIndexView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.APPROVED.INDEX,
						path: 'approved',
					},
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationCorrectionExportedIndexView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.EXPORTED.INDEX,
						path: 'exported',
					},
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationCorrectionRejectedIndexView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REJECTED.INDEX,
						path: 'rejected',
					},
				],
			},
		],
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: async () => import('@/modules/compensations/views/CompensationsView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.INDEX,
		path: '/compensations',
		redirect: { name: ROUTE_NAME.COMPENSATIONS.ADVANCES.INDEX },
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.ADVANCES.DETAIL,
		path: '/compensations/advances/:employeeUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () => import('@/modules/compensations/views/payments/CompensationsPaymentsDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.DETAIL,
		path: '/compensations/payments/:employeeUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () =>
			import('@/modules/compensations/views/corrections/detail/CompensationCorrectionProgressDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS.DETAIL,
		path: '/compensations/correction/:employeeUuid/in-progress/:compensationUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () =>
			import('@/modules/compensations/views/corrections/detail/CompensationCorrectionExportedDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.EXPORTED.DETAIL,
		path: '/compensations/correction/:employeeUuid/exported/:compensationUuid/:exportUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () =>
			import('@/modules/compensations/views/corrections/detail/CompensationCorrectionApprovedDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.APPROVED.DETAIL,
		path: '/compensations/correction/:employeeUuid/approved/:compensationUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () =>
			import('@/modules/compensations/views/corrections/detail/CompensationCorrectionRequestedDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REQUESTED.DETAIL,
		path: '/compensations/correction/:employeeUuid/requested/:compensationUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () =>
			import('@/modules/compensations/views/corrections/detail/CompensationCorrectionRejectedDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REJECTED.DETAIL,
		path: '/compensations/correction/:employeeUuid/rejected/:compensationUuid',
	},
]
